import questionService from "../Services/question.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async addQuestion(
    { commit },
    questionData = {
      questionText,
      yesScore,
      noScore,
      approvedConditionalIndicator,
      approvedConditionalText,
      treatmentIds,
    }
  ) {
    const response = await questionService.addQuestion(questionData);
    return response;
  },

  async editQuestion(
    { commit },
    questionData = {
      questionText,
      yesScore,
      noScore,
      approvedConditionalIndicator,
      approvedConditionalText,
      treatmentIds,
      dependentQuestionId
    }
  ) {
    const response = await questionService.editQuestion(questionData);
    return response;
  },

  async filterQuestions({ commit }, treatmentIds) {
    const response = await questionService.filterQuestions(treatmentIds);
    return response;
  },

  async fetchQuestions({ commit }) {
    const response = await questionService.fetchQuestions();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
