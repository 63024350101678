export default {
  API_URL: "https://api-dev.healthcoversonline.com/api",
  USER_LOGIN: "/login",
  PROVIDER_SIGNUP: "/provider",
  CUSTOMER_SIGNUP: "/customer",
  FETCH_CUSTOMERS: "/customer",
  FETCH_PROVIDERS: "/provider",
  UPDATE_CUSTOMER: "/customer/status",
  UPDATE_PROVIDER: "/provider/status",
  FILTER_CUSTOMER: "/customer?status=",
  FILTER_PROVIDER: "/provider?status=",
  CREATE_DIRECTOR: "/director",
  SIGNATURE_FILE: "/file",
  FETCH_CUSTOMER_ID: "/customer/",
  UPLOAD_CUSTOMER_CONTRACT: "/customer/contract",
  ADD_CATEGORY: "/category",
  FETCH_CATEGORIES: "/category",
  ADD_TREATMENT: "/treatment",
  FETCH_TREATMENTS: "/treatment",
CREATE_TREATMENT_DOSAGE: "/dosage",
  DELETE_DOSAGE: "/dosage",
  FETCH_TREATMENT_DOSAGE: "/dosage/treatment/",
  ADD_PATIENT: "/patient",
  FETCH_PATIENT: "/patient",
  FETCH_PATIENT_ID: "/patient/",
  CREATE_GFE: "/gfe",
  FETCH_GFE: "/gfe",
  UPLOAD_PROVIDER_CONTRACT: "/provider/contract",
  FETCH_PROVIDER_BY_ID: "/provider/",
  FETCH_PATIENT_WISE_GFE: "/gfe?patientid=",
  FETCH_DIRECTOR: "/director",
  FETCH_QUEUED_GFES: "/gfe/queue",
  FETCH_QUEUED_GFES_TO_FIX: "/gfe/fix/queued",
  MAP_GFE_RAW: "/gfe/map",
  GFE_UPDATE_AND_FIX: "/gfe/fix",
  JOIN_GFE: "/gfe/join",
  END_VIDEO_CALL: "/gfe/completed",
  SAVE_GFE_RAW: "/gfe/raw",
  DISCARD_GFE: "/gfe/raw",
  FETCH_GFE_RAW: "/gfe/raw",
  ADD_LOCATION: "/location",
  FETCH_LOCATIONS: "/location",
  DELETE_LOCATION: "/location",
  UPDATE_LOCATION: "/location",
  FETCH_CUSTOMER_TREATMENT: "/customer/treatments",
  UPDATE_CUSTOMER_TREATMENT: "/customer/treatments",
  ADD_MANUFACTURER: "/manufacture",
  FETCH_MANUFACTURER: "/manufacture",
  UPDATE_MANUFACTURER: "/manufacture",
  DELETE_MANUFACTURER: "/manufacture",
  ADD_COMPLIANCES: "/compliance",
  FETCH_COMPLIANCES: "/compliance",
  UPDATE_COMPLIANCES: "/compliance",
  DELETE_COMPLIANCES: "/compliance",
  FETCH_PATIENT_MEDICAL_HISTORY: "/patient",
  PING_GFE: "/gfe/ping",
  FILTER_GFE_CUSTOMER_PROVIDER: "/gfe",
  FILTER_CUSTOMER_BY_LOCATION: "/customer",
  UPDATE_PATIENT: "/patient",
  CREATE_DIREECTOR_DETAIL: "/director/detail",
  UPDATE_DIRECTOR: "/director/status",
  FETCH_GFE_BY_ID: "/gfe",
  ADD_CUSTOMER_DIRECTOR_COMMENT: "/gfe/review",
  UPDATE_CATEGORY: "/category",
  UPDATE_TREATMENT: "/treatment",
  EDIT_CREDENTIALS: "/auth/changepassword",
  FETCH_MEDICAL_DIRECTOR: "/director/medical",
  PATIENT_JOIN: "/gfe/connect",
  PATIENT_DISCONNECT: "/gfe/disconnect",
  RESEND_INVITATION_CUSTOMER: "/customer/resend?type=contract&id=",
  RESEND_INVITATION_PROVIDER: "/provider/resend?type=contract&id=",
  NOTIFY_END_CALL: "/gfe/endcall",
  PROVIDER_DASHBOARD: "/provider/dashboard",
  FETCH_INVOICE: "/invoice",
  CREATE_INVOICE: "/customer/invoicing",
  GFE_COUNT_AND_SUMMARY: "/gfe/count-report",
  ADMIN_DASHBOARD: "/admin/dashboard",
  FETCH_MONTHY_GFE_TREND: "/gfe/dashboard",
  FETCH_PATIENTS_ONLINE_COUNT: "/patient/online",
  FETCH_TOTAL_CUSTOMER_COUNT: "/customer/count-report",
  PROVIDER_PRESENCE_PING: "/provider/ping",
  GET_ONLINE_PROVIDERS_COUNT: "/provider/online",
  FETCH_INVOICE_BY_Id: "/invoice",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/password/reset",
  CREATE_ADVERTISEMENT: "/advertisement",
  FETCH_ADVERTISEMENT: "/advertisement",
  ADD_QUESTION: "/question",
  EDIT_QUESTION: "/question",
  FETCH_QUESTION: "/question",
  // FETCH_CUSTOMER_MEDICAL_DIRECTOR: "/customer/medical",
  FETCH_GFE_PATIENT: "/gfe/room",
  GFES_IN_PROGRESS: "/gfe/joined",
  MARK_INVOICE_PAYMENT: "/payment",
  MAKE_INVOICE_PAYMENT: "/payment/session",
  SET_USER_STATUS_ACTIVE_OR_INACTIVE: "/user/active/status",
  CHANGE_USER_EMAIL: "/user/email",
  UPDATE_GFE: "/gfe",
  START_RECORDING: "/gfe/recording/start",
  UPDATE_OPERATING_HOURS: "/operatinghour",
  FETCH_OPERATING_HOURS: "/operatinghour",
  FILTER_INVOICE_BY_STATE: "/invoice?stateid=",
  RESEND_INVOICE_BY_INVOICE_ID: "/invoice/resend?invoiceid=",
  GENERATE_INVOICE_BY_CUSTOMER_ID: "/invoice/generate?customerid=",
  GET_PAST_DUE_BILLINGS: "/invoice/unpaid/amount",
  GET_REVENUE_CHART_DATA: "/invoice/dashboard",
  FILTER_GFE_ROOMS: "/gfe/rooms",
  CUSTOMER_CONTRACTS: "/customer/director/contracts",
  CREATE_PROVIDER_LOCATION: "/provider/location",
  GET_PROVIDER_LOCATION: "/provider/:id/location",
  FETCH_MEDICAL_DIRECTOR_CUSTOMERS: "/medical-director/customer",
  CREATE_TICKER: "/ticker",
  FETCH_TICKER: "/ticker",
};
